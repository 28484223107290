import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  ANALYTICS,
  CONTENT,
  SUBSCRIPTION,
  SYSTEM,
} from "helpers/constants/Common/Admin";
import { showToast } from "redux/toaster";
import { getIsAdmin } from "helpers/utils";
import logError from "helpers/utils/logErrors";

// Components
import SuperAdmin from "components/PrivatePages/Dashboard/SuperAdmin";
import ContentAdmin from "components/PrivatePages/Dashboard/Conetnt";
import SubscriptionAdmin from "components/PrivatePages/Dashboard/Subscription";
import SystemAdmin from "components/PrivatePages/Dashboard/System";
import AnalyticsAdmin from "components/PrivatePages/Dashboard/Analytics";
import AddAdmin from "components/PrivatePages/Dashboard/Modal/AddAdmin";

// API
import { dashboardData } from "config/apis/dashboard.js";
import Loader from "components/Common/Loader";

/**
 * Dashboard UI
 * @returns { Component }
 */
const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state?.user?.backboneAdmin);
  const isSuperAdmin = useSelector((state) => state?.user?.isSuperAdmin);
  const [types, setTypes] = useState("");
  const [data, setData] = useState();
  const [addAdminModal, setAddAdminModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (token) {
      const decoded = jwt_decode(token);
      setTypes(decoded?.type || "");
    } else {
      const storedToken = localStorage.getItem("backboneAdmin");
      if (storedToken) {
        const decoded = jwt_decode(storedToken);
        setTypes(decoded?.type || "");
      } else {
        history.push("/");
      }
    }
  }, [token]);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    setIsLoading(true);
    try {
      const res = await dashboardData();
      if (res?.data?.result) {
        setData(res.data.result);
      }
    } catch (error) {
      logError("Dashboard", error);
      dispatch(
        showToast({
          message: error?.response?.data?.error,
          type: "error",
        })
      );
      if (error?.response?.status === 401) {
        localStorage.removeItem("backboneAdmin");
        history.push("/");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getHeadding = (type, isSuperAdmin) => {
    if (isSuperAdmin) {
      return "Hi Super Admin!";
    } else {
      switch (type) {
        case CONTENT:
          return "Content Admin";
        case SUBSCRIPTION:
          return "Subscription Admin";
        case SYSTEM:
          return "System Admin";
        case ANALYTICS:
          return "Research & Analytics Admin";
        default:
          return "";
      }
    }
  };

  const getPlans = (plans) => {
    const freePlan = plans.find((p) => p?.subscription_name === "Free");
    const restPlans = plans.filter((p) => p?.subscription_name !== "Free");
    return freePlan ? [freePlan, ...restPlans] : restPlans;
  };

  const renderComponent = (type) => {
    if (isSuperAdmin) {
      return <SuperAdmin data={data} onUpdate={fetchDashboardData} />;
    } else {
      if (getIsAdmin(type, CONTENT)) {
        return (
          <ContentAdmin
            onUpdate={fetchDashboardData}
            faqs={data?.faq || []}
            events={data?.events || []}
            blogs={data?.blogs || []}
          />
        );
      }
      if (getIsAdmin(type, SUBSCRIPTION)) {
        return (
          <SubscriptionAdmin
            users={data?.users || []}
            onUpdate={fetchDashboardData}
            subscriptions={getPlans(data?.subscriptions || [])}
            ecosystems={data?.ecosystems || []}
            unverified={data?.unverified || []}
          />
        );
      }
      if (getIsAdmin(type, SYSTEM)) {
        return (
          <SystemAdmin
            promocodes={data?.promocodes}
            onUpdate={fetchDashboardData}
          />
        );
      }
      if (getIsAdmin(type, ANALYTICS)) {
        return <AnalyticsAdmin analyticsData={data?.analytics} />;
      }
    }
    return null;
  };

  return (
    <>
      {isLoading && <Loader />}
      {isSuperAdmin ? (
        <div className="p-5">
          <div className="mb-6 flex flex-col items-centerspace-y-3 md:space-y-0 md:flex-row md:items-center justify-between">
            <div>
              <h1 className="text-xl md:text-4xl font-poppins font-normal">
                {getHeadding(types, isSuperAdmin)}
              </h1>
            </div>
            <div className="pt-3">
              <button
                className="border-[#039BE5] text-[#039BE5] text-xl font-bold w-[13.5rem] bg-inherit border-[1px] rounded-lg h-10 cursor-pointer transform transition duration-150 ease-in-out hover:scale-105"
                onClick={() => setAddAdminModal(true)}
              >
                Add admin
              </button>
            </div>
          </div>
          {renderComponent(types)}
          <AddAdmin
            addAdminModal={addAdminModal}
            setAddAdminModal={setAddAdminModal}
            onUpdate={fetchDashboardData}
          />
        </div>
      ) : (
        (types?.includes(",") ? types.split(",") : [types])?.map((type) => (
          <div key={type} className="p-5">
            <div className="mb-6 flex flex-col items-centerspace-y-3 md:space-y-0 md:flex-row md:items-center justify-between">
              <div>
                <h1 className="text-xl md:text-4xl font-poppins font-normal">
                  {getHeadding(type, false)}
                </h1>
              </div>
            </div>
            {renderComponent(type)}
          </div>
        ))
      )}
    </>
  );
};

export default Dashboard;
